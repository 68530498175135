import { imageOk, isImageOK } from "./image-ok";

export function waitLoad() {
    return new Promise( (resolve)=>{
        if (document.readyState !== 'loading') {
            try {
                resolve();
            }
            catch(e) {
                console.error('While executing callback: ', e)
            }
        } else {
            document.addEventListener('DOMContentLoaded', () => {
                try {
                    resolve();
                }
                catch(e) {
                    console.error('While executing callback: ', e)
                }
            });
        }
    } )
}

export function imgLoad(img) {
    return new Promise( (resolve,reject)=>{
        if(!isImageOK) {
            img.addEventListener('load', ()=>resolve(img));
            img.addEventListener('error', (e)=>reject(e));
        }
        else resolve(img);
    })
}