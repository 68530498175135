class ProviderList {
    constructor() {
        this.providerList = {};
    }
    register(name, newClass) {
        this.providerList[name] = newClass;
    }
    instantiate(name, defaultClass, ...args) {
        try {
            let constructor = (this.providerList[name] || defaultClass);
            let instance = Object.create(constructor.prototype);
            constructor.apply(instance, args);
            return instance;
        }
        catch(e) {
            console.error('Instantiation of ' + name + ' failed with error: ', e);
        }
    }
}

export const providerList = new ProviderList();