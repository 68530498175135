import { tns } from "tiny-slider/src/tiny-slider";
import { waitLoad } from "../utilities/load";

const DEFAULT_PARAMS = {
    container: ''
}

const SLIDER_CONFIG = {
    gutter: 0,
    swipeAngle: false,
    controls: false,
    mouseDrag: true,
    items: 1,
    navPosition: 'bottom',
    preventScrollOnTouch: 'force',
    responsive: {
        '541': {
            items: 2
        },
        '769': {
            items: 3,
            preventScrollOnTouch: 'auto',
        }
    }
}

export class SmallBlockSlider {
    constructor(aparams) {
        this.params = Object.assign({}, DEFAULT_PARAMS, aparams);

        this.sliderEl = document.querySelector('.slider');
   
            waitLoad().then(()=>{
                this.sliderEl = document.querySelector('.slider')
                if (!this.sliderEl) {
                    console.warn(this.params.selector + ' not found')
                }
                else {
                    this.init();
                }  
            })
    }

    init() {
        this.slider = tns(Object.assign({},SLIDER_CONFIG, this.params));
    }
}