const MobileDetect = window.MobileDetect || new (require('mobile-detect'))(window.navigator.userAgent);
import { waitLoad } from '../utilities/load';

const DEFAULT_PARAMS = {

}

export class VideoLink {
    constructor(params={}) {
        this.params = Object.assign({},DEFAULT_PARAMS,params);
        this.code = '';
        waitLoad().then(()=>{
            this.init();
        });
    }

    init() {
        //init youtube api
        const tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        //bind events on links
        let links = document.querySelectorAll('[data-youtube-link]')
        let that = this;
        
        window.onYouTubeIframeAPIReady = function() {
            links.forEach(link => {
                let onClick = (e) => {
                    links.forEach((el) => {
                        if (el.matches('.playing')) {
                            let elIframe = el.getElementsByTagName('iframe')[0];
                            if (!elIframe) {
                                return
                            }
                            el.classList.remove('playing');
                            elIframe.parentNode.removeChild(elIframe);
                        }
                    })
                    e.preventDefault();
                    e.stopPropagation();

                    that.code = link.getAttribute('data-youtube-link')

                    if (!that.code)
                        return;

                    if (!link.matches('.playing')) {
                        let container = document.createElement('div');
                        container.id = 'ytplayer-' + that.code;
                        link.appendChild(container);
                        link.classList.add('playing');
                        new YT.Player('ytplayer-' + that.code, {
                            videoId: that.code,
                            playerVars: {
                                cc_load_policy: 0,
                                allowFullscreen: true,
                                autoplay: 1,
                                origin: 'https://' + window.location.hostname,
                                enablejsapi: 1,
                                modestBranding: 1,
                                rel: 0,
                                showinfo: 0,
                            },
                            events: {
                                'onReady': (event) => {
                                    event.target.playVideo();
                                },
                            }
                        });
                    }

                    return false;
                }
                link.addEventListener('click', onClick)
            })
        }
    }
}