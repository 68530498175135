import simpleParallax from "simple-parallax-js";
import { waitLoad } from '../utilities/load';

const DEFAULT_PARAMS = {
    selector: '.cta-parallax-img'
}
export class CtaParallax {
    constructor(params={}) {
        this.params = Object.assign({},DEFAULT_PARAMS,params);
        waitLoad().then(()=>{
            let ctaParallaxImg = document.querySelectorAll(this.params.selector);
            if (ctaParallaxImg) {
                new simpleParallax(ctaParallaxImg, {
                    delay: .6,
                    transition: 'cubic-bezier(0,0,0,1)'
                });
            }
        })
    }
    init() {

    }
}