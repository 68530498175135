import Pristine from 'pristinejs';

const PRISTINE_CONFIG = {
    // class of the parent element where the error/success class is added
    classTo: 'input-wrap',
    errorClass: 'has-danger',
    successClass: 'has-success',
    // class of the parent element where error text element is appended
    errorTextParent: 'input-wrap',
    // type of element to create for the error text
    errorTextTag: 'div',
    // class of the error text element
    errorTextClass: 'text--danger'
};

const DEFAULT_FORM_CONFIG = {
    closeModalSelector: ".close__lightbox",
    confirmSelector: ".wrapper_lightbox_confirm"
}

const modalsSubmisionsDelayInDays = 90;

export class FormValidation {
    constructor(formSelector, options) {
        this.formSelector = formSelector;
        this.formEl = null;
        this.pristine = null;
        this.options = Object.assign({}, DEFAULT_FORM_CONFIG, options)
        let hasLoaded = false;
        window.addEventListener("load", () => {
            if (!this.formEl)
                return;

            // create the pristine instance
            this.pristine = new Pristine(this.formEl, PRISTINE_CONFIG, false);

            this.bindSubmit();

            //check if there is a file field in the form
            this.bindFileField();
            hasLoaded = true;
        });

        document.addEventListener('DOMContentLoaded', () => {
            this.formEl = document.querySelector(this.formSelector);
            this.bindModal();
            hasLoaded = true;
        })
        setTimeout(() => {
            if (!hasLoaded) {
                this.formEl = document.querySelector(this.formSelector);
                this.bindModal();
                if (!this.formEl)
                    return;

                // create the pristine instance
                this.pristine = new Pristine(this.formEl, PRISTINE_CONFIG, false);
                this.bindSubmit();

                //check if there is a file field in the form
                this.bindFileField();

            }
        }, 1000)
    }

    bindSubmit() {
        this.formEl.addEventListener('submit', (e) => {
            e.preventDefault();
            const form = this.formEl;
            const formSelector = this.formSelector;
            // check if the form is valid
            var valid = this.pristine.validate(); // returns true or false
            if (valid) {
                if(formSelector === '#frm_newsletter_pop-up' || formSelector === "#frm_newsletter"){
                    if(!form.action.includes('redirectPage')){
                        form.action = form.action + "?redirectPage=" + form.getAttribute('data-current-page');
                    }
                    if(formSelector === '#frm_newsletter_pop-up'){
                        form.action = form.action + "&fromPopUp=true";
                    }
                    const date = new Date();
                    date.setTime(date.getTime() + (modalsSubmisionsDelayInDays * 24 * 60 * 60 * 1000));
                    var expires = "expires=" + date.toUTCString();
                    document.cookie = "newsletterHasBeenSubmited =" + true + ";" + expires + ";path=/";
                    form.submit();
                } else{
                    grecaptcha.ready(function () {
                        grecaptcha.execute('6Le1XMEZAAAAAH4ScUO9vwt5Vmeyg6p3hM1qYQUt', {action: 'submit'}).then(function (token) {
                            form.action = form.action + "?token=" + token;
                            form.submit();
                        });
                    });
                }
            }
        });
    }

    bindFileField() {
        var fileInputs = document.querySelectorAll('[data-upload-field]');
        this.formEl.addEventListener('change', (e) => {
            for (let input of fileInputs) {
                let txt_element = input.querySelector('.text');
                let input_field = input.querySelector('input')
                txt_element.innerHTML = input_field.value.replace(/C:\\fakepath\\/i, '');
            }
        });

        for (let input of fileInputs) {
            let txt_element = input.querySelector('.button');
            let input_field = input.querySelector('input');
            txt_element.addEventListener('click', () => {
                input_field.click();
            })
        }
    }

    bindModal() {
        function fadeOut(el) {
            el.style.opacity = 1;

            (function fade() {
                if ((el.style.opacity -= .1) < 0) {
                    el.style.display = "none";
                } else {
                    requestAnimationFrame(fade);
                }
            })();
        }

        function fadeIn(el, display) {
            el.style.opacity = 0;
            el.style.display = display || "block";

            (function fade() {
                var val = parseFloat(el.style.opacity);
                if (!((val += .1) > 1)) {
                    el.style.opacity = val;
                    requestAnimationFrame(fade);
                }
            })();
        }
        let closeConfirmEl = document.querySelectorAll(this.options.closeModalSelector);
        let confirmBox = document.querySelector(this.options.confirmSelector);
        if (closeConfirmEl && confirmBox) {
            closeConfirmEl.forEach(function (elem) {
                elem.addEventListener('click', () => {
                    fadeOut(confirmBox)
                });
            });
        }
    }
}



