import "@iconfu/svg-inject";
import { waitLoad } from "../utilities/load";

waitLoad().then(()=>{
    let els = document.getElementsByClassName('svgToInline');
    for(var i in els) {
        if(els.hasOwnProperty(i) ) {
            console.log(els[i])
            SVGInject(els[i]);
        }
    }
});