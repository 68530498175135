
import { ScrollMore } from '@blax-modules/base-front-end/resources/js/ui/scroll-more';
import { FullscreenSlider } from '@blax-modules/base-front-end/resources/js/sliders/fullscreen-slider';
import { Modal } from "@blax-modules/base-front-end/resources/js/ui/modal";
import { waitLoad } from "@blax-modules/base-front-end/resources/js/utilities/load";


const DEFAULT_PARAMS = {

}
export class Home {
    constructor() {
        this.init();

        waitLoad().then(()=>{
            this.closePopup();
        })
    }

    init() {
        let homeEl = document.querySelector('.homemain');
        if(!homeEl)
            return;
        this.initScrollMore();
        this.initSlider();
    }

    initScrollMore() {
        new ScrollMore();
    }

    initSlider() {
        let autoplay_speed = slider_autoplay_speed;
        let autoplay = false;
        let sliderCtn = document.querySelector('.home-banner');
        let sliderType = sliderCtn.getAttribute('data-slider-type');
        let sliderTransitionTime = sliderCtn.getAttribute('data-slider-transition');

        if(sliderType) {
            autoplay = sliderType;
        }

        if(sliderTransitionTime) {
            autoplay_speed = sliderTransitionTime
        } else if(autoplay_speed <= 0){
            autoplay_speed = 6000;
        }

        new FullscreenSlider({
            container: '.home-slider',
            autoplay: autoplay,
            autoplayTimeout: autoplay_speed,
            loop: true
        })
    }

    closePopup() {
        let popups = document.querySelectorAll('.wrapper_lightbox_confirm');
        popups.forEach((el)=>{
            let closeButton = el.querySelector('.x_close')
            closeButton.addEventListener('click', ()=>{
                el.classList.add('closed')
            })
            let overlay = el.querySelector('.overlay__lightbox');
            overlay.addEventListener('click', (e)=>{
                if(e.currentTarget === e.target) {
                    el.classList.add('closed')
                }
            })
        })
    }
}
