//CANCELOVERWRITE
import {providerList} from "@blax-modules/base-front-end/resources/js/utilities/providers";
import { Home } from '@blax-modules/general_templates/resources/js/parts/home';
import { FullscreenSlider } from '@blax-modules/base-front-end/resources/js/sliders/fullscreen-slider';

require('./_modules')

//exemple d'overwrite de class pour personnaliser le slider
class CustomHome extends Home {
    initSlider() {
        new FullscreenSlider({
            container: '.home-slider',
            sliderConfig: {
                autoplayTimeout: 1000,
            }
        })
    }
}
providerList.instantiate('Home', CustomHome);

//laravel stuff
window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

