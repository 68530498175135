export function isImageOK(img) {
    if (!img.complete) {
        return false;
    }

    if (typeof img.naturalWidth !== "undefined" && img.naturalWidth === 0) {
        return false;
    }
    
    return true;
}