import { waitLoad, imgLoad } from "../utilities/load";

export class Cover {
    constructor() {
        waitLoad().then(() => {
            this.imgs = document.querySelectorAll('.cover');
            for (let img of this.imgs) {
                imgLoad(img).then(this.handleImg);
                img.addEventListener('updateCover', () => {
                    this.handleImg(img)
                });
            }
        });

        window.addEventListener('resize', () => {
            this.onResize();
        })
    }

    handleImg(img) {
        img.classList.remove('cover-horizontally');

        let w = img.offsetWidth;
        let h = img.offsetHeight;
        let pw = img.parentNode.offsetWidth;
        let ph = img.parentNode.offsetHeight;

        let isHorizontal = (w / h < pw / ph);

        if (isHorizontal)
            img.classList.add('cover-horizontally');
    }

    onResize() {
        this.imgs = document.querySelectorAll('.cover');
        if (!this.imgs)
            return;
        for (let img of this.imgs) {
            this.handleImg(img)
        }
    }
}

export function updateImage(element) {
    let event = document.createEvent("HTMLEvents");
    event.initEvent("updateCover", true, true);
    event.eventName = "updateCover";
    element.dispatchEvent(event)
}