//https://github.com/zloirock/core-js

import "core-js/modules/es.array.from";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.iterator";
import "core-js/modules/es.array.of";
import "core-js/modules/web.dom-collections.for-each";
import "core-js/modules/web.dom-collections.iterator";
import "core-js/modules/es.object.assign";
import "core-js/modules/es.symbol";
