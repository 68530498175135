import { FormValidation } from '@blax-modules/base-front-end/resources/js/forms/form-validation';
import { waitLoad } from '@blax-modules/base-front-end/resources/js/utilities/load';


export class Contact {
    constructor() {
        let el = document.querySelector('.contactmain');
        if(!el)
            return;

        new FormValidation("#frm_contact")
        waitLoad().then(()=>{
            this.init();
        });
    }

    init() {
        const select = document.querySelector('#txt_question');

        if(select) {
            select.addEventListener('change', function() {
                if(select.classList.contains('isOpen')){
                    setTimeout(()=> {
                        select.classList.remove('isOpen');
                    }, 0)
                }
            }, false);

            select.addEventListener('click', function() {
                if(!select.classList.contains('isOpen')){
                    select.classList.toggle('isOpen');
                }
            }, false);

            document.querySelector('main').addEventListener('click', function(e) {
                if(e.target !== select && select.classList.contains('isOpen')){
                    select.classList.remove('isOpen');
                }
            }, false);
        }
    }
}
