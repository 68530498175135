import { waitLoad } from "@blax-modules/base-front-end/resources/js/utilities/load"

const DEFAULT_PARAMS = {
    sliderSelector: '.home-banner',
    messageSelector: '.gdpr-message',
    hideOnScroll: ':not(.homepage)'
};

export class Header {
    constructor(params) {
        this.params = Object.assign({},DEFAULT_PARAMS, params);
        waitLoad().then(() => this.init());
    }

    init() {
        /* When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar */
        let that = this;
        var prevScrollpos = window.pageYOffset;
        let siteHeader = document.getElementsByClassName('main-header')[0]
        let homeHeader = document.querySelector(this.params.sliderSelector);
        let gdprClose = document.querySelector('.gdpr-close');
        if(this.doHideOnScroll(siteHeader)) {
            window.addEventListener('scroll',  function () {
                var currentScrollPos = window.pageYOffset;
                let minYScroll = 0;
                if (homeHeader) {
                    minYScroll = homeHeader.offsetHeight;
                }

                if (prevScrollpos > currentScrollPos || currentScrollPos < 0) {
                    setTimeout(function () {
                        if (siteHeader) {
                            siteHeader.classList.remove("menu-pos");
                        }
                        if (document.getElementById('navigation') != null) {
                            document.getElementById('navigation').classList.remove("nav-pad");
                        }
                    }, 0);
                } else if (currentScrollPos > minYScroll) {
                    setTimeout(function () {
                        if (siteHeader) {
                            siteHeader.classList.add("menu-pos");
                        }
                        if (document.getElementById('navigation') != null) {
                            document.getElementById('navigation').classList.add("nav-pad");
                        }
                    }, 0);
                }
                prevScrollpos = currentScrollPos;
                adjustHeader()
            })
        }


        if (gdprClose) {
            gdprClose.addEventListener('click', () => {
                adjustHeader()
            })
        }

        function adjustHeader() {
            // We execute the same script as before
            document.querySelector('.site-navigation__wrapper').style.minHeight = (window.innerHeight - 99) + 'px'
            if (!siteHeader.nextElementSibling.matches(that.params.sliderSelector)) {
                    let gdpr = document.querySelector('.gdpr-message');
                    if(gdpr && gdpr.classList.contains('visible')) {
                        document.querySelector('main').style.marginTop = siteHeader.offsetHeight + (gdpr.offsetHeight / 2 ) + 'px';
                    } else {
                        document.querySelector('main').style.marginTop = siteHeader.offsetHeight + 'px';
                    }
                } else {
                let message = document.querySelector('.alert-banner');
                if(message && message.classList.contains('visible')) {
                    let slider = document.querySelector('.home-banner.isCentered');
                    slider.style.marginTop = message.offsetHeight + 'px';
                    message.querySelector('.alert-close').addEventListener('click', () => {
                        slider.style.marginTop = "0px";
                    })
                }
            }
        };
        window.addEventListener('resize', adjustHeader)
        window.addEventListener('load', adjustHeader)
    }

    doHideOnScroll(el) {
        if(typeof this.params.hideOnScroll === 'string') {
            return !!el.matches(this.params.hideOnScroll);
        }
        else
            return !!this.params.hideOnScroll;
    }
}
