import { tns } from "tiny-slider/src/tiny-slider";
import { waitLoad } from "../utilities/load";

const DEFAULT_PARAMS = {
    container: '',
    primaryColor: '1B326C',
    secondaryColor: 'B91522',
    alternateSecondaryColor: 'FF0000',
    blackColor: '000000',
    whiteColor: 'FFFFFF',
    sliderConfig: {}
}

export class FullscreenSlider {
    constructor(aparams) {
        this.params = Object.assign({}, DEFAULT_PARAMS, aparams);
        waitLoad().then(() => this.init());
    }


    init() {
        const SLIDER_CONFIG = {
            container: this.params.container,
            items: 1,
            controls: false,
            slideBy: "page",
            mouseDrag: false,
            autoplay: this.params.autoplay,
            autoplayButtonOutput: false,
            swipeAngle: 30,
            autoplayTimeout: this.params.autoplayTimeout,
            preventScrollOnTouch: 'auto',
            navPosition: 'bottom',
            mode: "gallery",
            loop: false
        }

        this.setBtnColor();

        let sliderEl = document.querySelector(this.params.container)
        if (!sliderEl) {
            return
        }
        let slider = tns(Object.assign({},SLIDER_CONFIG,this.params.sliderConfig));
        this.initVideo(sliderEl);
        this.initHomeParallax()
        this.onIndexChanged();
        slider.events.on('indexChanged', this.onIndexChanged);
        this.hideHomeSliderOnScroll(sliderEl);
        this.setNavColor();
    }
    setBtnColor = () => {
        let slides = document.querySelectorAll('.tns-item');
        if(slides.length === 0)
            slides = document.querySelectorAll('.home-banner__content')

        for(let x = 0; x < slides.length; x++) {
            let btn = slides[x].querySelector('.new-slider-btn');
            if(btn){
                let curColors = [btn.style.backgroundColor, btn.style.color];
                btn.addEventListener('mouseover', function() {
                    btn.style.backgroundColor = this.getAttribute('data-hover-bg-color');
                    btn.style.color = this.getAttribute('data-hover-text-color');
                });
                btn.addEventListener('mouseout', function() {
                    btn.style.backgroundColor = curColors[0];
                    btn.style.color = curColors[1];
                })
            }
        }
    }
    setNavColor = () => {
        let slides = document.querySelectorAll('.tns-item');
        let tnsNav = document.querySelectorAll('.tns-nav button');
        for(let x = 0; x <= slides.length; x++) {
            if(slides[x]){
                let color = slides[x].getAttribute('data-color');
                if (tnsNav[x]) {
                    tnsNav[x].style.backgroundColor = '#' + color;
                }
            }
        }
    }

    onIndexChanged = (info, eventName) => {
        let slide = document.querySelector(".tns-slide-active")
        let colorSetted = slide.getAttribute("data-color")
        let scrollBlock = document.querySelector(".scroll-block")
        if(scrollBlock) {
            scrollBlock.classList.remove('scroll-block--primary');
            scrollBlock.classList.remove('scroll-block--secondary');
            scrollBlock.classList.remove('scroll-block--black');
            scrollBlock.classList.remove('scroll-block--white');

            if (colorSetted === this.params.primaryColor) {
                scrollBlock.classList.add('scroll-block--primary')
            } else if (colorSetted === this.params.secondaryColor) {
                scrollBlock.classList.add('scroll-block--secondary')
            } else if (colorSetted === this.params.alternateSecondaryColor) {
                scrollBlock.classList.add('scroll-block--secondary')
            } else if (colorSetted === this.params.blackColor) {
                scrollBlock.classList.add('scroll-block--black')
            } else if (colorSetted === this.params.whiteColor) {
                scrollBlock.classList.add('scroll-block--white')
            }
        }

        let btnSlideEl = slide.querySelector('.btn--slide');
        if (!btnSlideEl)
            return;

        btnSlideEl.addEventListener('mouseenter', () => {
            btnSlideEl.getElementsByTagName('span')[0].style.display = ''
            btnSlideEl.addEventListener('mouseleave', (e) => {
                e.preventDefault();
                btnSlideEl.classList.add('slideOut')
                setTimeout(() => btnSlideEl.classList.remove('slideOut'), 250)
                setTimeout(() => btnSlideEl.getElementsByTagName('span')[0].style.display = 'none', 250)
                setTimeout(() => btnSlideEl.getElementsByTagName('span')[0].style.display = '', 500)
            })
        })
    }
    initVideo = (sliderEl) => {
        let newHeight, wrapper = sliderEl.closest('.home-banner'), videoEls = sliderEl.querySelectorAll('video');
        const resizeHandler = function () {
            const resize = function (vidEl) {
                let hasParallax = matchMedia('screen and (min-width:1024px)').matches, rect = vidEl.getBoundingClientRect(),
                    videoWidth = rect.width,
                    videoHeight = rect.height,
                    viewportHeight = window.innerHeight,
                    wrapperWidth = wrapper.getBoundingClientRect().width,
                    wrapperHeight;

                wrapperHeight = viewportHeight * (hasParallax ? 1.2 : 1);

                if (videoWidth / videoHeight < wrapperWidth / wrapperHeight) {
                    vidEl.style.width = wrapperWidth + 'px';
                    vidEl.style.height = 'auto';
                    vidEl.classList.remove('vertical')
                }
                else {
                    vidEl.style.width = 'auto';
                    if (hasParallax)
                        vidEl.style.height = wrapperHeight + 'px';
                    else
                        vidEl.style.height = '100vh';
                    vidEl.classList.add('vertical')
                }
            }

            const onLoad = function (vidEl) {
                console.log('video loaded')
                let rect = vidEl.getBoundingClientRect();

                if (rect.width && rect.height) {
                    resize.call(null, vidEl)
                }
                vidEl.parentElement.classList.remove('preloading-like')
            }

            const play = function (vidEl) {
                var sourceEl = vidEl.querySelector('source');
                vidEl.classList.remove('hidden');

                if (!sourceEl.getAttribute('src')) {
                    var time = function () {
                        if (vidEl.readyState >= 3) {
                            onLoad.call(null, vidEl);
                        }
                        else {
                            setTimeout(time, 400)
                        }
                    };
                    setTimeout(time, 400)
                    vidEl.parentElement.classList.remove('preloading-like')
                    sourceEl.setAttribute('src', sourceEl.getAttribute('data-src'));
                    vidEl.load();
                }
                else {
                    var w = vidEl.videoWidth, h = vidEl.videoHeight;
                    if (w && h) {
                        resize.call(null, vidEl)
                    }
                }
            }

            videoEls.forEach((vid) => {
                let rect = vid.getBoundingClientRect();
            })

            if (matchMedia('screen and (min-width:1024px)').matches) {
                videoEls.forEach((vid) => {
                    if (vid.matches('.slider-desktop')) {
                        play(vid)
                    }
                    else {
                        vid.classList.add('hidden')
                    }
                })
            }
            else {
                videoEls.forEach((vid) => {
                    if (vid.matches('.slider-mobile')) {
                        play(vid)
                    }
                    else {
                        vid.classList.add('hidden')
                    }
                })
            }
        }

        window.addEventListener('resize', resizeHandler)
        resizeHandler();
    }
    initHomeParallax = () => {
        const banner = document.querySelector(this.params.container);
        document.addEventListener('scroll', () => {
            let transXVal = this.getTranslateX(banner)
            let scrollPos = document.documentElement['scrollTop']
            banner.style.transform = 'translate3d(' + transXVal + '%,-' + (scrollPos / 2) + 'px, 0px)'
            banner.style.willChange = 'transform'
            banner.style.transition = 'transform 0.4s cubic-bezier(0,0,0,1)'
        }, { passive: true });
    }
    getTranslateX = (myElement) => {
        const elStyle = myElement.getAttribute('style')
        if (elStyle && elStyle.length > 0) {
            let tmpStr = myElement.getAttribute('style').split('translate3d(');
            let subStr1 = tmpStr.splice(tmpStr.indexOf(tmpStr))
            let transStr = subStr1[0].split('%,')
            return transStr[0];
        }
    }
    hideHomeSliderOnScroll = (sliderEl) => {
        window.addEventListener('scroll', (e) => {
            const sliderParent = sliderEl.parentElement.parentElement;
            if (window.scrollY > window.innerHeight) {
                if (!sliderParent.classList.contains('hide')) {
                    sliderParent.classList.add('hide');
                }
            } else {
                if (sliderParent.classList.contains('hide')) {
                    sliderParent.classList.remove('hide');
                }
            }
        });
    }
}

export class slider {
    constructor(containerClass) {
        this.containerClass = containerClass;
        this.sliderHome();
    }


}
