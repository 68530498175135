const defaultParams = {
    selector: '.progress-bar',
}

export class ProgressBar {
    constructor(params = {}) {
        this.params = Object.assign({}, defaultParams, params);
        let h = document.documentElement,
            b = document.body,
            st = 'scrollTop',
            sh = 'scrollHeight',
            progress = document.querySelector(this.params.selector),
            scroll;

        document.addEventListener('scroll', function () {
            scroll = (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
            progress.style.setProperty('--scroll', scroll + '%');
        }, {passive: true});
    }
}
